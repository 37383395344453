<template>
  <div>
    <general-table
      ref="winnersTable"
      :api-url="APIURL"
      :columns="columns"
      :delete-content="false"
      :block-content="false"
      :edit-content="false"
      :view-content="false"
      :type="type"
      :selectable="false"
    >
      <template #cell(winner_name)="data">
        <b-avatar
          size="50"
          :src="data.item.image"
        />
        <router-link
          class="mx-1"
          :to="{ name: 'show-user-customer', params: { id: data.item.winner_id } }"
        >
          {{ data.item.winner_name }}
        </router-link>
      </template>
      <template #cell(type)="data">

        {{ getTypeName(data.item.type) }}
      </template>
      <template #cell(products_name)="data">
        <span
          v-for="(product,index) in data.item.products_name"
          :key="index"
        >
          {{ product }}
          <span v-if="index < data.item.products_name.length - 1">,</span>

        </span>
      </template>
      <template #cell(is_active)="data">
        <b-badge
          :variant="changeVaraint(data.item.is_active)"
        >
          {{ getStatusName(data.item.is_active) }}
        </b-badge>
      </template>
      <template #cell(winner_photo)="data">
        <b-img
          v-img
          width="50"
          :src="data.item.winner_photo"
        /></template>
      <template #cell(action)="data">
        <span
          title="add winner photo"
          class="mx-1"
        >
          <feather-icon

            icon="EditIcon"
            @click="openImgDialog(data.item.product_gift_id)"
          />
        </span>

        <span
          title="Edit Winner"
        >
          <feather-icon
            icon="UserPlusIcon"
            class="cursor-pointer"
            @click="viewmodel(data.item)"
          />
        </span>

      </template>
    </general-table>
    <div>
      <b-modal
        id="addImg"
        ref="addImg"
        hide-footer
        centered
        title="Add Image"
      >

        <b-row>

          <b-col cols="12">
            <b-media>

              <b-media-aside>

                <div class="d-flex">

                  <b-img
                    ref="previewEl"
                    v-img
                    :src="formImg.image"
                    class="ml-1 typeImg"
                    height="300"
                    width="400"
                  />

                </div>
                <!--/ image -->
              </b-media-aside>

              <b-media-body class="mt-75 ml-75">
                <!-- upload button -->
                <b-button
                  variant="primary"
                  size="sm"
                  class="mb-75 mr-75"
                  @click="selectFile()"
                >
                  Upload Image
                </b-button>
                <validation-provider
                  #default="validationContext"
                  name="type image"
                >
                  <b-form-group>
                    <b-form-file
                      ref="refInputEl"
                      accept=".jpg, .png , .jpeg"
                      :hidden="true"
                      plain
                      multiple
                      :state="getValidationState(validationContext)"
                      @change="changeProfile($event) "
                    />
                  </b-form-group>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
                <!--/ upload button -->
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>

        <b-col
          cols="12"
          md="12"
        >
          <b-button
            v-if="!loaderAddImg"
            variant="primary"
            class="mr-1"
            :disabled="formImg.image==null"
            @click.prevent="addImg"
          >
            Save
          </b-button>
          <b-button
            variant="outline-danger"
            class="closeModal"
            @click="closeModal()"
          >
            Cancel
          </b-button>
          <b-button
            v-if="loaderAddImg"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-modal>
    </div>
    <div>
      <b-modal
        id="userstatus"
        ref="hide-modal"
        hide-footer
        centered
        title="Display Winner"
      >
        <b-row>
          <b-col
            cols="12"
            class="p-2"
          >
            <b-col
              md="12"
            >
              <validation-provider
                #default="validationContext"
                name="Alert Before Days"
                rules="required"
              >
                <b-form-group
                  v-slot="{ ariaDescribedby }"
                  label="Type"
                >
                  <b-form-radio-group
                    v-model="winnerForm.type"
                    :aria-describedby="ariaDescribedby"
                    name="type"
                  >
                    <b-form-radio

                      value="1"
                    >
                      Millionaires
                    </b-form-radio>
                    <b-form-radio

                      value="2"
                    >
                      Winners
                    </b-form-radio>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-radio-group>
                </b-form-group>

              </validation-provider>
            </b-col>
            <b-col
              md="6"
            >
              <b-form-group
                label="Active"
                label-for="Active"
              >
                <b-form-checkbox
                  id="Active"
                  v-model="winnerForm.is_active"
                  unchecked-value="0"
                  value="1"
                  class="custom-control-secondary"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-col>

          <b-col
            cols="12"
            md="12"
          >
            <b-button
              v-if="!loader"
              variant="primary"
              class="mr-1"
              :disabled="disableUpdateStatusBtn"
              @click.prevent="editWinner()"
            >
              Save
            </b-button>
            <b-button
              variant="outline-danger"
              class="closeModal"
              @click="closeModal()"
            >
              Cancel
            </b-button>
            <b-button
              v-if="loader"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner
                small
                type="grow"
              />
              Loading...
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref } from '@vue/composition-api'
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import handleFormData from '@/global-compositions/handleFormData'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'admin/winners',
      type: 'page',
      urlType: 'winners',
      columns: [
        { key: 'winner_name', label: 'Winner' },
        { key: 'winner_photo', label: 'Winner Photo' },
        { key: 'products_name', label: 'Products' },
        { key: 'gift_name', label: 'Gift name' },
        { key: 'is_active', label: 'Status' },
        { key: 'type', label: 'type' },
        { key: 'ticket_code', label: 'Ticket' },
        // { key: 'product_gift_id', label: 'Campaign' },
        { key: 'action' },

      ],
      campaignId: '',
      image: null,
      userId: '',
      loaderAddImg: false,

    }
  },
  setup() {
    const formImg = ref({
      image: null,

    })
    const winnerForm = ref({
      is_active: 0,
    })
    const {
      getValidationState,
    } = formValidation()
    const { formData, setFormData } = handleFormData()

    return {
      getValidationState,
      formImg,
      winnerForm,
      formData,
      setFormData,
    }
  },
  methods: {

    openImgDialog(id) {
      this.formImg.image = null
      this.campaignId = id
      this.$refs.addImg.show()
    },
    viewmodel(data) {
      this.userId = data.id
      this.winnerForm.type = data.type
      this.winnerForm.is_active = data.is_active

      this.$bvModal.show('userstatus')
    },
    closeModal() {
      this.$bvModal.hide('userstatus')

      this.$refs.addImg.hide()
    },
    changeProfile(e) {
      // eslint-disable-next-line prefer-destructuring
      this.image = e.target.files[0]
      const input = e.target
      const img = new Image()
      img.src = window.URL.createObjectURL(e.target.files[0])
      img.onload = () => {
        const standard = 1.62 / 0.98
        const belowStandard = 1.62 / 1.05
        const userRatio = img.width / img.height
        if (userRatio <= standard && userRatio >= belowStandard) {
          if (input.files) {
            const reader = new FileReader()
            reader.onload = er => {
              this.formImg.image = er.target.result
            }

            // eslint-disable-next-line prefer-destructuring
            this.image = input.files[0]
            reader.readAsDataURL(input.files[0])
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Image Aspect Ratio is Not Valid',
              icon: 'BellIcon',
              variant: 'danger',
              duration: 4000,

            },
          })
        }
      }
    },

    editWinner() {
      this.loader = true

      axios.post(`admin/change_active_status/${this.userId}`,
        this.winnerForm).then(res => {
        if (res.status === 200) {
          this.closeModal()
          this.$refs.winnersTable.getAllData()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Winner is updated Succesfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        }
      }).finally(() => {
        this.loader = false
      })
    },
    selectFile() {
      this.$refs.refInputEl.$el.click()
    },
    changeVaraint(type) {
      // eslint-disable-next-line eqeqeq
      if (type == '1') return 'success'

      return 'danger'
    },
    getStatusName(type) {
      // eslint-disable-next-line eqeqeq
      if (type == '1') return 'Displayed'

      return 'Hidden'
    },
    getTypeName(type) {
      // eslint-disable-next-line eqeqeq
      if (type == '1') return 'Millionaires'

      return 'Winners'
    },
    getHideTxt(type) {
      // eslint-disable-next-line eqeqeq
      if (type == '0') return 'Are you sure you want to hide this user from winners List ?'

      return 'Are you sure you want to display this user in winners List ?'
    },
    addImg() {
      const formData = new FormData()
      this.loaderAddImg = true
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      formData.append('winner_photo', this.image)

      axios.post(`admin/update_winner_photo/${this.campaignId}`, formData).then(res => {
        if (res.status === 200) {
          this.$refs.addImg.hide()
          this.loaderAddImg = false

          this.$toasted.show('Added Successfully', {
            position: 'top-center',
            duration: 3000,
          })
        }
      }).catch(error => {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ooops!!! An Unexpected Internal server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else if (error.response.status === 422) {
          if (error.response.data.errors !== undefined) {
            this.errorMsg = error.response.data.errors
          } else {
            this.errorMsg = error.response.data.message
          }
        }
      }).finally(() => {
        this.loaderAddImg = false
      })
    },
  },

}
</script>

  <style>

  </style>
